let Bootstrap = window.bootstrap = require('bootstrap/dist/js/bootstrap.min');

function getCookie(name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length == 2) return parts.pop().split(";").shift();
  return '';
}
// let ddfum = new Bootstrap.Dropdown(document.querySelector('#footerUserMenu'));
let ddfumb = document.querySelector('#link0');
document.addEventListener('click', function(event) {
  let target = event.target;
  let isClicked = false;
  let check = target;
  do {
    if (check == ddfumb) {
      isClicked = true;
      break;
    }
    check = check.parentNode;
  } while (check && check != document.body)
  if (isClicked) {
    event.preventDefault();
    if (getCookie('full_name')) {
      return false;
    } else {
      if (!~[ddfumb.href, '/login'].indexOf(window.location.pathname)) {
        window.location.href = ddfumb.href;
      }
      event.stopPropagation();
      return false;
    }
  }
}, true);

window.showNavMenu = function showNavMenu() {
  document.getElementById('Dropdown').classList.toggle('show')
    if (window.innerWidth < 768) {
      let icon = document.getElementById('headicon')
      icon.classList.toggle('icon-menu')
      icon.classList.toggle('icon-close')
    }
}

window.addEventListener('click', function (event) {
  if (!event.target.matches('.header__right__nav__menu') && !event.target.matches('.nav__menu') && !event.target.matches('.nav__menu__item') && !event.target.matches('.nav__overlay')) {
    let dropdowns = document.getElementsByClassName("nav");
    let openDropdown = dropdowns[0];
    if (openDropdown.classList.contains('show')) {
      if (window.innerWidth < 768) {
        let icon = document.getElementById('headicon')
        icon.classList.toggle('icon-menu')
        icon.classList.toggle('icon-close')
      }
      openDropdown.classList.remove('show');
    }
  }
  if (event.target.matches('.nav__overlay')) {
    window.showNavMenu()
  }
}, true);

// window.onscroll = function() {
//   let header = document.getElementById('header')
//   let sticky = header.offsetTop
//   if (window.pageYOffset > sticky) {
//     console.log(window.pageYOffset)
//     // console.log(window.pageYOffset, '>', sticky)
//     // console.log(document.body.style.paddingTop)
//     document.body.style.marginTop += '130px'
//     header.classList.add("sticky");
//     if (window.outerWidth < 767) {
//       document.body.style.marginTop = 0
//     }
//     return
//   } else {
//     document.body.style.marginTop = 0
//     header.classList.remove("sticky");
//   }
// }

window.onload = function () {
  // console.log(window.location.pathname)
  for (let i = 0; i < 4; i++) {
    let link = document.getElementById('link' + i)
    if (link.attributes.href.value + '/' == window.location.pathname) {
      // console.log(link.children[0])
      link.classList.add("colored-link")
      link.children[0].classList.add("colored-link")

    }
    // console.log(link.attributes.href.value)
  }
  // border-bottom: 2px #6f6f6f solid
  let links = document.getElementsByClassName('nav__menu__item')
  for (let i = 0; i < links.length; i++) {
    // console.log(links[i].children[0].attributes.href.value)
    if (links[i].children[0].attributes.href.value + '/' == window.location.pathname) {
      links[i].children[0].classList.add("underlined-link")
    }
  }
}
