// window.initNews = function() {
  import $ from 'jquery';
  window.onload = function() {
    // console.log(window.location.pathname)
    if ((window.location.pathname === '/news/') || (window.location.pathname.search(/news_item\/\d/g) === 1)) {
      $.getJSON("/news/getList", function(data){
        var stock_list = data
        // console.log(stock_list)
        if (window.location.pathname === '/news/') {
          // console.log(window.location.pathname)
        } else {
          for (let i = 0; i < stock_list.length; i++) {
            for (let j = 0; j < stock_list[i].length; j++) {
            // console.log(window.location.pathname.substring(11))
            // console.log(stock_list[0][0])
            // console.log(window.location.pathname.substring(11), '===', stock_list[i][j].pk)
            if (stock_list[i][j].pk.toString() === window.location.pathname.substring(11)) {
              let prev = document.getElementById('prev')
              let next = document.getElementById('next')
              if (stock_list[i] && stock_list[i][j-1]) {
                prev.href = '/news_item/' + stock_list[i][j-1].pk
              } else if(stock_list[i-1] && stock_list[i-1][2]) {
                prev.href = '/news_item/' + stock_list[i-1][2].pk
              } else {
                prev.remove()
              }
              if (stock_list[i] && stock_list[i][j+1]) {
                // console.log(1111)
                next.href = '/news_item/' + stock_list[i][j+1].pk
              } else if(stock_list[i+1] && stock_list[i+1][0]) {
                // console.log(2222)
                next.href = '/news_item/' + stock_list[i+1][0].pk
              } else {
                // console.log(3333)
                next.remove()
              }
            }
          }
        }
      }
      })
    } else if ((window.location.pathname === '/promo/') || (window.location.pathname.search(/promo_item\/\d/g) === 1)) {
      $.getJSON("/promo/getList", function(data){
        var stock_list = data
        // console.log(stock_list, '-spisok')
        if (window.location.pathname === '/promo/') {
          // console.log(window.location.pathname, 1111)
        } else {
          for (let i = 0; i < stock_list.length; i++) {
            // console.log(window.location.pathname.substring(12))
            // console.log(stock_list[i].pk.toString())
            if (stock_list[i].pk.toString() === window.location.pathname.substring(12)) {
              let prev = document.getElementById('prev')
              let next = document.getElementById('next')
              if (stock_list[i-1]) {
                prev.href = '/promo_item/' + stock_list[i-1].pk
              } else {
                prev.remove()
              }
              if (stock_list[i+1]) {
                next.href = '/promo_item/' + stock_list[i+1].pk
              } else {
                next.remove()
              }
            }
          }
        }
      })
    }

    // console.log(window.location.pathname)
  for (let i = 0; i < 4; i++) {
    let link = document.getElementById('link' + i)
    // console.log(link.attributes.href.value)
    if (link.attributes.href.value + '/' == window.location.pathname) {
      // console.log(link.children[0])
      link.classList.add("colored-link")
      link.children[0].classList.add("colored-link")

    }
    // console.log(link.attributes.href.value)
  }
  // border-bottom: 2px #6f6f6f solid
  let links = document.getElementsByClassName('nav__menu__item')
  for (let i = 0; i < links.length; i++) {
    // console.log(links[i].children[0].attributes.href.value)
    if (links[i].children[0].attributes.href.value + '/' == window.location.pathname) {
      links[i].children[0].classList.add("underlined-link")
    }
  }
      
    }
  // }