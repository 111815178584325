import Inputmask from "inputmask";
const axios = require('axios');

window.pressEnter = function pressEnter(e) {
  if (e.keyCode == 13) {
    window.callBack()
  }
}

window.callBack = function callBack() {
  // console.log(document.getElementById('callback').value.length)
  let data = document.getElementById('callback').value
  for (let i =0; i < 16;i++) {
    data = data.replace(/\D/, '')
  }
  if (data.length == 11) {
    console.log(data)
    axios.post('/backCall/', {"contact": data}, success())
  }
};

window.success = function success() {
  let a = document.getElementById('feedback') 
  a.innerHTML = '<p class="footer__feedback__title">Благодарим за оставленную заявку</p><p class="footer__feedback__title min">Менеджер в ближайшее время свяжется с вами для обработки вашего запроса</p>'
}

document.addEventListener('DOMContentLoaded', function(){
  var elem = document.getElementById('callback');
  var im = new Inputmask("+7(999) 999-99-99");
  im.mask(elem);
});